.home-div {
    margin-bottom: 10px;
    margin-top: -25px;
}

.home-banner-div {
    background-image: url('../Assets/images/main_banner.jpg');
    background-size: cover;
}

.home-div-title {
    display: flex;
}

.home-title1 {
    color: var(--color-graydark) !important;
}

.home-title2 {
    color: black !important;
}

.home-section-header {
    color: var(--color-graydark) !important;
    font-size: 30px !important;
}

.log-box {
    height: 7rem;
    border-radius: 10px 10px 10px 10px;
}

.card-box {
    height: 35rem;
    border-radius: 10px 10px 10px 10px;
}

.home-customers-desc {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    font-size: 20px;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 300;
    color: var(--color-graydark) !important;
}

#home-electronic-billing:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#home-electronic-billing-header {
    color: white;
}

.img-section {
    height: 60vh !important;
}

.texto {
    font-size: 24px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}

.app-btn {
    padding: 10px 50px;
    font-size: 21px;
    font-family: 'Lato';
}

.text-animation {
    position: relative;
    display: inline-block;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 481px) {
    .start {
        padding: 15% 25% 10% 3%;
        background-position: center center;
    }
    .home-container {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
    .home-electronic-billing {
        padding: 15% 25% 10% 3%;
    }
    .home-banner-div {
        height: 260px;
        background-position: center center;
    }
    .home-div-title {
        width: 70% !important;
        padding-top: 50px !important;
        margin-left: 20px;
        margin-right: 20px;
    }
    .home-title1 {
        font-size: 26px !important;
    }
    .home-title2 {
        font-size: 26px !important;
    }
    #home-electronic-billing {
        height: 40vh !important;
    }
    #home-electronic-billing-header {
        font-size: 22px !important;
        padding-top: 190px;
        padding-left: 15px;
        padding-right: 25px;
    }
    #home-electronic-billing-button {
        margin-left: 15px;
    }
    .home-technical-support {
        padding: 15% 25% 10% 3%;
        height: 40vh !important;
    }
    .home-section-header {
        font-size: 30px !important;
    }
    #home-vigilance-section {
        height: 40vh !important;
    }
    .home-lab-section {
        height: 40vh !important;
    }
    .img-section {
        height: 40vh !important;
    }
}

@media (min-width: 481px) {
    .start {
        padding: 15% 25% 10% 3%;
        background-position: center center;
    }
    .home-container {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }
    .home-electronic-billing {
        padding: 15% 25% 10% 3%;
    }
    .home-banner-div {
        height: 360px;
        background-position: center center;
    }
    .home-div-title {
        width: 50% !important;
        padding-top: 90px !important;
        margin-left: 60px !important;
    }
    .home-title1 {
        font-size: 32px !important;
    }
    .home-title2 {
        font-size: 32px !important;
    }
    #home-electronic-billing {
        height: 370px;
    }
    #home-electronic-billing-header {
        font-size: 28px !important;
        padding-top: 190px;
        padding-left: 15px;
        padding-right: 25px;
    }
    /* #home-electronic-billing-button {
    margin-left: 15px;
  } */
    .home-technical-support {
        padding: 15% 25% 10% 3%;
    }
    .home-section-header {
        font-size: 30px !important;
    }
}

@media (min-width: 1024px) {
    .start {
        padding: 15% 25% 10% 3%;
        background-position: center center;
    }
    .home-container {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }
    .home-electronic-billing {
        padding: 15% 25% 10% 3%;
    }
    .home-banner-div {
        height: 390px;
        background-position: center center;
    }
    .home-div-title {
        width: 50% !important;
        padding-top: 90px !important;
        margin-left: 60px !important;
    }
    .home-title1 {
        font-size: 32px !important;
    }
    .home-title2 {
        font-size: 32px !important;
    }
    #home-electronic-billing {
        height: 370px;
    }
    #home-electronic-billing-header {
        font-size: 32px !important;
        padding-top: 190px;
        padding-left: 15px;
        padding-right: 25px;
    }
    #home-electronic-billing-button {
        margin-left: 15px;
    }
    .home-technical-support {
        padding: 15% 25% 10% 3%;
    }
    .home-section-header {
        font-size: 30px !important;
    }
}

@media (min-width:1200px) {
    .start {
        padding: 15% 25% 10% 3%;
        background-position: center center;
    }
    .home-container {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }
    .home-electronic-billing {
        padding: 15% 25% 10% 3%;
    }
    .home-banner-div {
        height: 540px;
        background-position: center center;
    }
    .home-div-title {
        width: 30% !important;
        padding-top: 130px !important;
        margin-left: 160px !important;
    }
    .home-title1 {
        font-size: 38px !important;
    }
    .home-title2 {
        font-size: 38px !important;
    }
    #home-electronic-billing {
        height: 570px;
    }
    #home-electronic-billing-header {
        font-size: 38px !important;
        padding-top: 190px;
        padding-left: 15px;
        padding-right: 25px;
    }
    #home-electronic-billing-button {
        margin-left: 15px;
    }
    .home-technical-support {
        padding: 15% 25% 10% 3%;
    }
    .home-section-header {
        font-size: 36px !important;
    }
}

@media (max-width: 970px) {
    .clickpack{
        clip-path: none!important;
    }
    .img-section {
        height: 35vh !important;
    }
}

.carousel-wrapper {
    display: flex;
    justify-content: center;
}

.arrow {
    position: absolute;
    z-index: 80;
    top: 50%;
    transform: translateY(-50%);
}

html {
    scroll-behavior: smooth;
}
.btn-check {
    display: none; /* Oculta por defecto */
  }
.btn-service[aria-selected="true"] .btn-check {
    display: block; /* Muestra cuando aria-selected="true" */
  }
.btn-service[aria-selected="true"]::before {
    content: "";
    display: block;
    width: 10px;   
    height: 10px; 
    background-color: #ffcc00; 
    margin-right: 8px;
    position: absolute;
    right: 45%;
    bottom: -10px;
    clip-path: polygon(49% 0, 0 100%, 100% 100%);
    rotate: 180deg;
  }
  .background-app {
    background: url('../Assets/images/marco-imagen.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}