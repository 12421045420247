
.page-banner-grid {
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.page-banner-img {

}


@media (max-width: 481px) {
  .page-banner-grid {
    background-size: auto 180px;
    padding-bottom: 30px !important;
  }
}

@media (min-width: 481px) {
  .page-banner-grid {
    background-size: auto 300px;
    padding-bottom: 20px !important;
  }
}

@media (min-width: 1024px) {
  .page-banner-grid {
    background-size: auto 400px !important;
    min-height: 380px;
  }
}

@media (min-width:1200px) {
  .page-banner-grid {
    background-size: auto 450px !important;
    min-height: 430px;
  }
}
