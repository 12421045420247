.faq-segment {
  padding: 30px !important;
}

.faq-header-topic {
  font-weight: bolder !important;
  font-size: 30px !important;
  color: var(--color-bluedark) !important;
}

.faq-div-ask-reply {
  padding-top: 20px !important;
  padding-bottom: 25px;
}

.header-ask {
  font-size: 20px !important;
}

.faq-text-reply {
  font-size: 18px;
  color: var(--color-graydark);
}
