.dashboard-row {
  overflow: hidden;
  flex-wrap: nowrap !important;
}

#row-white {
  background-color: white;
}

#row-gray {
  background-color: rgb(236, 236, 236);
}

.dashboard-section-title {
  font-size: 38px;
  color: var(--color-graydark) !important;
}

.dashboard-section-text {
  font-size: 26px;
  width: 70%;
  color: var(--color-graydark) !important;
}

.dashboard-img {
  object-fit: cover;
  overflow: hidden;
  object-position: left;
  height: 100%;
}

@media (max-width: 481px) {
  .dashboard-page {
    margin-bottom: 100px;
  }

  .dashboard-row {
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    height: 280px !important;
    width: 100%;
  }

  .dashboard-section-desc {
    padding-top: 10px;
    padding-left: 10px;
  }

  .dashboard-section-title {
    font-size: 16px;
  }

  .dashboard-section-text {
    font-size: 14px;
    width: 90%;
  }

  .dashboard-img {
    /* height: 100%; */
  }

  .dashboard-col-img {
    text-align: center !important;
    height: 100%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (min-width: 481px) {
  .dashboard-page {
    margin-bottom: 120px;
  }

  .dashboard-row {
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 0px !important;
    height: 350px !important;
    width: 100%;
  }

  .dashboard-section-desc {
    padding-top: 20px;
    padding-left: 10px;
  }

  .dashboard-section-title {
    font-size: 26px;
  }

  .dashboard-section-text {
    font-size: 18px;
    width: 90%;
  }

  .dashboard-img {
    height: 100%;
  }

  .dashboard-col-img {
    text-align: center !important;
    height: 100%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

}

@media (min-width:1024px) {
  .dashboard-page {
    margin-bottom: 200px;
  }

  .dashboard-row {
    padding-top: 50px !important;
    padding-left: 230px !important;
    padding-right: 230px !important;
    padding-bottom: 0px !important;
    height: 550px !important;
    width: 100%;
  }

  .dashboard-section-title {
    font-size: 38px;
  }

  .dashboard-section-desc {
    padding-top: 70px;
    padding-left: 90px !important;
  }

  .dashboard-section-text {
    font-size: 26px;
    width: 70%;
  }

  .dashboard-col-img {
    text-align: center !important;
    height: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

@media (min-width:1200px) {
  .dashboard-page {
    margin-bottom: 200px;
  }

  .dashboard-row {
    padding-top: 50px !important;
    padding-left: 230px !important;
    padding-right: 230px !important;
    padding-bottom: 0px !important;
    height: 550px !important;
    width: 100%;
  }

  .dashboard-section-title {
    font-size: 38px;
  }

  .dashboard-section-desc {
    padding-top: 70px;
    padding-left: 90px !important;
  }

  .dashboard-section-text {
    font-size: 26px;
    width: 70%;
  }

  .dashboard-col-img {
    text-align: center !important;
    height: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
