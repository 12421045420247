
#electronic-billing-main-text {
  font-size: 20px;
  padding-bottom: 30px;
  color: white !important;
  font-weight: lighter;
}

.electronic-billing-text-p {
  font-size: 22px;
  color: white !important;
}

.text-why-electronic-billing {
  color: white !important;
}

.text-why-prefer-us {
  padding-top: 40px !important;
  color: white !important;
}
