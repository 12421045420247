.grid-dashboard {
  margin-top: 50px !important;
  margin-bottom: 90px !important;
}

.home-dashboard-img {
  object-fit: contain;
  background-position: center right;
  background-repeat: no-repeat;
}

.home-dashboard-text {
  color: var(--color-graydark) !important;
  font-weight: normal;
}

.dashboard-grid-row {
  padding: 0px !important;
}

.grid-dashboard-button {
  text-align: left !important;
  margin-left: auto !important;
  /* font-size: 16px !important; */
}


@media (max-width: 481px) {
  .grid-dashboard {
    /* min-height: 600px; */
  }

  .home-dashboard-text {
    font-size: 24px;
    text-align: left;
  }

  .dashboard-col1 {
    padding-top: 0px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .home-dashboard-img {
    max-height: 300px !important;
  }

  .grid-dashboard-button {
    font-size: 18px !important;
  }

  .grid-dashboard-div-button {
    padding-top: 5px;
    text-align: end !important;
  }

  .home-dashboard-col-img{
    padding-top: 0px !important;
  }
}

@media (min-width: 481px) {
  .grid-dashboard {
    /* min-height: 600px; */
  }

  .home-dashboard-text {
    font-size: 22px;
    text-align: left;
  }

  .dashboard-col1 {
    padding-top: 60px !important;
    padding-left: 50px !important;
    padding-right: 10px !important;
  }

  .home-dashboard-img {
    max-height: 400px !important;
  }

  .grid-dashboard-button {
    font-size: 16px !important;
  }

  .grid-dashboard-div-button {
    padding-top: 20px;
    text-align: start !important;
  }
}

@media (min-width: 1024px) {
  .grid-dashboard {
    min-height: 600px;
  }

  .home-dashboard-text {
    font-size: 32px;
    text-align: right !important;
    margin-left: auto !important;
  }

  .home-dashboard-img {
    /* max-height: 200px !important; */
  }

  .grid-dashboard-button {
    font-size: 20px !important;
  }

  .grid-dashboard-div-button {
    padding-top: 30px;
    text-align: start !important;
  }
}

@media (min-width:1200px) {
  .grid-dashboard {
    min-height: 600px;
  }

  .home-dashboard-text {
    font-size: 30px;
    text-align: left !important;
  }

  .home-dashboard-img {
    max-height: 450px !important;
  }

  .dashboard-col1 {
    padding-top: 100px !important;
    padding-left: 350px !important;
    padding-right: 50px !important;
  }

  .grid-dashboard-button {
    font-size: 20px !important;
  }

  .grid-dashboard-div-button {
    padding-top: 30px;
    text-align: start !important;
  }
}
