.contact-container {
  margin-top: 0px !important;
  margin-bottom: 30px !important;
}

.contact-list-item {
  padding-top: 20px !important;
}

@media (max-width:768px) {
  .contact-container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media (min-width:768px) {
  .contact-container {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

}
