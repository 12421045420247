.documentation-card {
    background-color: rgb(255, 255, 255);
    border-color: white !important;
    box-shadow: 0 2px 20px 0 rgba(138, 138, 138, 0.3);
    display: flex;
    border-radius: 10px;
}

.documentation-row-header {
    width: 100% !important;
}

.documentation-row-cards {
    width: 100% !important;
    display: flex;
}

.documentation-header {
    color: var(--color-graydark) !important;
    font-weight: bold !important;
    margin-bottom: auto !important;
    margin-top: auto !important;
    width: 80%;
}

.documentation-card-img {
    object-fit: cover !important;
    padding: 5px;
}

.documentation-card-content {
    min-height: 80px !important;
    padding: 10px;
    margin-top: 0px !important;
    color: var(--color-graydark);
    font-weight: normal !important;
}

.documentation-grid-section {
    width: 100% !important;
}

.documentation-row-col {
    margin-bottom: 20px !important;
}

@media (max-width: 481px) {
    .documentation-card {
        padding: 15px;
    }
    .documentation-header {
        font-size: 25px !important;
    }
    .documentation-header-icon {
        max-height: 30px;
        padding-right: 10px;
    }
    .documentation-grid-section {
        padding: 0px !important;
        margin-bottom: 30px !important;
    }
    .documentation-card-content {
        font-size: 18px;
    }
    .documentation-card-img {
        max-height: 50px !important;
    }
}

@media (min-width: 481px) {
    .documentation-card {
        padding: 20px;
    }
    .documentation-header {
        font-size: 30px !important;
    }
    .documentation-header-icon {
        max-height: 38px;
        padding-right: 10px;
    }
    .documentation-grid-section {
        padding: 0px !important;
        margin-bottom: 30px !important;
    }
    .documentation-card-content {
        font-size: 20px;
    }
    .documentation-card-img {
        max-height: 65px !important;
    }
}

@media (min-width: 1024px) {
    .documentation-card {
        padding: 30px;
    }
    .documentation-header {
        font-size: 35px !important;
    }
    .documentation-header-icon {
        max-height: 42px;
        padding-right: 20px;
    }
    .documentation-grid-section {
        padding: 0px !important;
        margin-bottom: 40px !important;
    }
    .documentation-card-content {
        font-size: 20px;
    }
    .documentation-card-img {
        max-height: 70px !important;
    }
}

@media (min-width:1200px) {
    .documentation-card {
        padding: 24px;
    }
    .documentation-header {
        font-size: 40px !important;
    }
    .documentation-header-icon {
        max-height: 40px;
        padding-right: 20px;
    }
    .documentation-grid-section {
        padding: 0px !important;
        margin-bottom: 50px !important;
    }
    .documentation-card-content {
        font-size: 24px;
    }
    .documentation-card-img {
        max-height: 70px !important;
    }
    .documentation-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}