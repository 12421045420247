
.page-banner-container {
  margin-left: 0px !important;
  margin-right: 0px !important;
  max-width: 50%;
}

.page-banner-desc {
  text-align: left !important;
  font-size: 30px;
}

#page-banner-desc-white {
  color: var(--color-graydark) !important;
}

#page-banner-desc-dark {
  color: white !important;
}

@media (max-width: 481px) {
  .page-banner-container {
    max-width: 55%;
  }

  .page-banner-desc {
    font-size: 18px;
  }
}

@media (min-width: 481px) {
  .page-banner-container {
    max-width: 55%;
  }

  .page-banner-desc {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .page-banner-container {
    max-width: 50%;
  }

  .page-banner-desc {
    font-size: 24px;
  }
}

@media (min-width:1200px) {
  .page-banner-container {
    max-width: 50%;
  }

  .page-banner-desc {
    font-size: 26px;
  }
}
