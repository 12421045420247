#integrations-main-text {
  font-size: 22px;
  padding-bottom: 30px;
  font-weight: lighter;
}

.integrations-img {
  height: 110px !important;
}

.integrations-col-img {
  text-align: center !important;
  height: 120px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

@media (min-width:1200px) {
  .integrations-row-img {
    padding-left: 260px !important;
    padding-right: 260px !important;
  }
}

@media (min-width:1024px) {
  .integrations-row-img {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
}

@media (min-width:768px) {
  .integrations-row-img {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width:481px) {
  .integrations-row-img {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
