.page-standard-grid {
    background-size: cover;
    overflow-y: visible;
    overflow: visible;
    background-repeat: repeat-y;
    min-height: 100% !important;
}

#page-dark {
    background-color: rgb(48, 52, 58) !important;
}

.page-standard-row {
    display: block !important;
}

.page-standard-row-body {
    display: block !important;
}

.page-standard-img {
    max-width: 100%;
    margin: auto;
    position: absolute;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.page-header {
    width: 100%;
    text-align: center;
}

#page-header-white {
    color: var(--color-graydark) !important;
}

#page-header-dark {
    color: white !important;
}

.page-header-desc {
    text-align: center;
}

#page-header-desc-white {
    color: var(--color-graydark) !important;
}

#page-header-desc-dark {
    color: white !important;
}

.page-img-whatsapp {
    width: 60px;
    text-align: center;
}

.page-button-contac-us {
    display: inline-flex !important;
    text-align: center;
    min-width: 220px;
    max-height: 80px !important;
}

.page-button-contac-us-text {
    font-size: 20px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    padding-left: 5px;
    padding-right: 10px;
}

@media (max-width: 481px) {
    .page-standard-grid {
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 40px !important;
    }
    .page-title-container {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .page-header {
        padding-top: 40px !important;
        font-size: 30px !important;
    }
    .page-header-desc {
        font-size: 18px !important;
    }
    .page-standard-img {
        margin-left: -20px !important;
        margin-right: -20px !important;
    }
    .page-container {
        padding-top: 30px !important;
        padding-bottom: 10px !important;
    }
}

@media (min-width: 481px) {
    .page-standard-grid {
        padding-left: 40px !important;
        padding-right: 40px !important;
        padding-bottom: 30px !important;
    }
    .page-title-container {
        padding-top: 30px !important;
        padding-bottom: 15px !important;
    }
    .page-header {
        padding-top: 50px !important;
        font-size: 40px !important;
    }
    .page-header-desc {
        font-size: 20px !important;
    }
    .page-standard-img {
        margin-left: -40px !important;
        margin-right: -40px !important;
    }
    .page-container {
        padding-top: 30px !important;
        padding-bottom: 20px !important;
    }
}

@media (min-width: 1024px) {
    .page-standard-grid {
        padding-left: 80px !important;
        padding-right: 80px !important;
        padding-bottom: 40px !important;
    }
    .page-title-container {
        padding-top: 40px !important;
        padding-bottom: 50px !important;
    }
    .page-header {
        padding-top: 50px !important;
        font-size: 40px !important;
    }
    .page-header-desc {
        font-size: 22px !important;
    }
    .page-standard-img {
        margin-left: -80px !important;
        margin-right: -80px !important;
    }
    .page-container {
        padding-top: 40px !important;
        padding-bottom: 20px !important;
    }
}

@media (min-width:1200px) {
    .page-standard-grid {
        padding-left: 200px !important;
        padding-right: 220px !important;
        padding-bottom: 50px !important;
    }
    .page-title-container {
        padding-top: 40px !important;
        padding-bottom: 20px !important;
    }
    .page-header {
        padding-top: 60px !important;
        font-size: 40px !important;
    }
    .page-header-desc {
        font-size: 24px !important;
    }
    .page-standard-img {
        margin-left: -220px !important;
        margin-right: -220px !important;
    }
    .page-container {
        padding-top: 40px !important;
        padding-bottom: 20px !important;
    }
}