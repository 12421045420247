.module-page-img {
    border-radius: 32% 68% 56% 44% / 36% 38% 62% 64%;
    height: 45vh;
    width: 25vw;
    background-size: cover;
}

.modules-container {
    height: 110vh;
    /* Adjust the height as needed */
    position: relative;
    background-color: white;
}

.modules-container::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Adjust the height of the diagonal cut as needed */
    background: linear-gradient(to bottom right, transparent, rgba(0, 0, 0, 0.1));
    transform: skewY(-8deg);
    /* Adjust the skew angle as needed */
    transform-origin: bottom left;
}