.card-container {
    background-color: rgb(255, 255, 255);
    border-color: white !important;
    box-shadow: 0 2px 20px 0 rgba(105, 105, 105, 0.2);
}

.card-image {
    /* margin-top: 10px !important; */
    /* width: 100% !important; */
}

.card-header-title {
    color: var(--color-graydark) !important;
}

.card-text-desc {
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
    color: var(--color-graydark) !important;
    /* font-size: 20px; */
}

.card-module {
    height: 280px;
    margin: 10px;
    border-width: 1px;
    border-style: solid;
}

@media (max-width: 481px) {
    .card-module {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .card-container {
        min-height: 400px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .card-header-title {
        font-size: 24px !important;
    }
    .card-text-desc {
        font-size: 18px;
    }
}

@media (min-width: 481px) {
    .card-module {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    .card-container {
        min-height: 440px !important;
    }
    .card-header-title {
        font-size: 24px !important;
    }
    .card-text-desc {
        font-size: 18px;
    }
}

@media (min-width:1024px) {
    .card-module {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
    .card-container {
        min-height: 460px !important;
    }
    .card-header-title {
        font-size: 26px !important;
    }
    .card-text-desc {
        font-size: 20px;
    }
}

@media (min-width:1200px) {
    .card-module {
        padding-left: 220px !important;
        padding-right: 220px !important;
    }
    .card-container {
        min-height: 450px !important;
    }
    .card-header-title {
        font-size: 26px !important;
    }
    .card-text-desc {
        font-size: 20px;
    }
}