
.solutions-tryton-desc {
  font-size: 25px !important;
  color: var(--color-graydark) !important;
}

.solutions-col {
  margin-bottom: 30px !important;
}

.solutions-row-title {
  width: 100%;
}

.solutions-row-modules {
  width: 100%;
}

@media (max-width: 481px) {
  .solutions-row-modules {
    padding-top: 20px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .solutions-row-title {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .solutions-col {
    padding: 0px !important;
  }
}

@media (min-width: 481px) {
  .solutions-row-modules {
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .solutions-row-title {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media (min-width:1024px) {
  .solutions-row-modules {
    padding-top: 30px !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .solutions-row-title {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}

@media (min-width:1200px) {
  .solutions-row-modules {
    padding-top: 30px !important;
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .solutions-row-title {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
}
