
.page-header-big {
  width: 100%;
  margin-top: auto !important;
  margin-bottom: 0px !important;
  text-align: left;
  font-size: 60px !important;
}

#page-header-big-white {
  color: var(--color-graydark) !important;
}

#page-header-big-dark {
  color: white !important;
}

@media (max-width: 481px) {
  .page-title-big-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 70px !important;
  }

  .page-header-big {
    font-size: 34px !important;
  }
}

@media (min-width: 481px) {
  .page-title-big-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 70px !important;
  }

  .page-header-big {
    font-size: 38px !important;
  }
}

@media (min-width: 1024px) {
  .page-title-big-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 70px !important;
  }

  .page-header-big {
    font-size: 42px !important;
  }
}

@media (min-width:1200px) {
  .page-title-big-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 70px !important;
  }

  .page-header-big {
    font-size: 60px !important;
  }
}
